.addEditMemberForm {
  padding: 20px;
  min-width: 440px;
}

.addEditMemberForm > h1 {
  padding-bottom: 20px;
  font-size: 30px;
}

.addEditMemberForm__member {
  display: flex;
  justify-content: space-between;
}

.addEditMemberForm__memberControl {
  min-width: 240px !important;
}

.addEditMemberForm__dashboardSelect {
  display: inline-flex !important;
}

.addEditMemberForm__pointsEntry {
  margin-right: 20px !important;
  max-width: 150px !important;
}

.addEditMemberForm__pointsExp {
  max-width: 200px !important;
}

.addEditMemberForm__controls {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.addEditMemberForm__controlSave {
  background: #4caf50 !important;
  color: white !important;
  margin-right: 10px !important;
}

.addEditMemberForm__controlSave.Mui-disabled {
  background: lightgray !important;
}

.addEditMemberForm__controlCancel {
  background: #f44336 !important;
  color: white !important;
}

.addEditMemberForm__controlDelete {
  background: #f44336 !important;
  color: white !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
}

.addEditMemberForm__certificatesTitle {
  margin-top: 20px;
}

.addEditMemberForm__certificatesAdd {
  display: flex;
  align-items: flex-end;
}

.addEditMemberForm__certificateExp {
  max-width: 200px !important;
}

.addEditMemberForm__certificatesAddButton {
  margin-left: 10px !important;
  margin-bottom: 6px !important;
  color: green !important;
}

.addEditMemberForm__certificatesAdd
  button.Mui-disabled
  .addEditMemberForm__certificatesAddButtonIcon {
  color: lightgray !important;
}

.addEditMemberForm__certificatesList {
  max-width: 300px;
}

.addEditMemberForm__certificatesRemoveButton {
  color: red !important;
}
