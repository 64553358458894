.businessList {
  min-width: 400px;
  margin: 20px 0;
}

.businessList > h3 {
  margin-bottom: 10px;
}

.businessList__list {
  padding-left: 10px;
}

.businessList__itemUrl {
  padding-left: 20px;
  font-size: smaller;
  font-style: italic;
}

.businessList__businessRemoveButton {
  color: red !important;
}
