.memberView {
  display: flex;
  justify-content: center;
  flex: 1;
}

.memberView__empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.memberView__empty > img {
  width: 400px;
}

@media only screen and (max-width: 1024px) {
  .memberView {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
