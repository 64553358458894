.company {
  width: 100%;
}

.company__header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: var(--color-4);
}

.company__name {
  display: flex;
  font-weight: 700;
}

.company__webSite {
  margin-left: 10px;
  cursor: pointer;
  color: gray;
}

.company__addIcon {
  cursor: pointer;
}

.company__addIcon > a {
  color: gray;
  transition: color 0.25s ease-in-out;
}

.company__addIcon:hover > a {
  color: black;
}

.company__rewards {
  /* padding: 5px; */
}

.company__reward {
  display: flex;
  justify-content: space-between;
}

.company__rewardMember {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  border: 1px solid lightgray;
  padding: 5px;
}

.company__rewardMember__lineOne {
  display: flex;
  align-items: center;
}

.company__rewardMember__lineTwo {
  padding: 0 10px;
}

.company__rewardMemberCertificate {
  font-size: small;
  color: gray;
}

.company__rewardMemberCertificate--alert {
  color: red;
  font-weight: 500;
}

.company__rewardMemberName {
  height: 24px;
  margin: 0 5px;
  font-weight: 500;
}

.company__rewardMember__editIcon,
.company__rewardMember__deleteIcon {
  transform: scale(0.8);
  cursor: pointer;
  margin: 0 5px;
  color: gray;
  transition: color 0.25s ease-in-out;
}

.company__rewardMember__editIcon a {
  color: gray;
  transition: color 0.25s ease-in-out;
}

.company__rewardMember__editIcon:hover a,
.company__rewardMember__deleteIcon:hover {
  color: black;
}

.company__rewardPoints {
  flex: 0.35;
  border: 1px solid lightgray;
  padding: 5px 10px;
  text-align: right;
  font-size: large;
  font-weight: 700;
}

.company__rewardPoints > p {
  /* font-style: italic; */
  font-size: x-small;
  color: gray;
  font-weight: 400;
}

.company__rewardPoints--alert,
.company__rewardPoints--alert > p {
  color: red;
}

@media only screen and (max-width: 640px) {
  .company__rewardMember {
    font-size: small;
  }

  .company__rewardMemberCertificate {
    font-size: smaller;
  }

  .company__rewardPoints {
    font-size: small;
  }
}
