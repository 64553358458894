.header {
  position: sticky;
  top: 0;
  height: 60px;
  background-color: var(--color-1);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > h1 {
  padding-left: 20px;
  color: white;
  font-style: italic;
}

.header--clickable {
  cursor: pointer;
}

.header__nav {
  padding-right: 20px;
}

.header__option {
  display: flex;
  flex-direction: column;
  color: white;
}

.header__optionLineOne {
  font-size: 10px;
}

.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}
