.profileForm {
  padding: 20px;
  min-width: 440px;
}

.profileForm > h1 {
  padding-bottom: 20px;
  font-size: 30px;
}

.profileForm__nameInput {
  min-width: 250px !important;
}

.profileForm__controls {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.profileForm__controlSave {
  background: #4caf50 !important;
  color: white !important;
  margin-right: 10px !important;
}

.profileForm__controlSave.Mui-disabled {
  background: lightgray !important;
}

.profileForm__controlCancel {
  background: #f44336 !important;
  color: white !important;
}

.profileForm__membersTitle {
  margin-top: 20px;
}

.profileForm__membersAdd {
  display: flex;
  align-items: flex-end;
}

.profileForm__membersAdd > p {
  margin-left: 10px;
  font-size: small;
}

.profileForm__memberExp {
  max-width: 200px !important;
}

.profileForm__membersAddButton {
  margin-left: 10px !important;
  margin-bottom: 6px !important;
  color: green !important;
}

.profileForm__membersAdd
  button.Mui-disabled
  .profileForm__membersAddButtonIcon {
  color: lightgray !important;
}

.profileForm__membersList {
  max-width: 300px;
}

.profileForm__membersRemoveButton {
  color: red !important;
}
