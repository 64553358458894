.sidebar {
  color: white;
  flex: 0.3;
  background-color: var(--color-1);
  border-top: 1px solid white;
  max-width: 260px;
  /* height: 100%; */
  transition: 0.5s margin ease;
}

.sidebar--slideHide {
  margin-left: -260px;
}

.sidebar > a {
  text-decoration: none;
}

.sidebar > a:link,
.sidebar > a:visited {
  color: white;
}

.sidebar > hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid white;
}

.sidebar__members {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.sidebar__membersIcon {
  font-size: 30px !important;
}
