.login {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.login__container {
  display: flex;
  width: 50%;
  min-width: 600px;
  max-width: 800px;
  border: 1px solid lightgray;
  /* transition: all 1s; */
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.login--signup {
  flex-direction: row-reverse;
  /* transition: all 1s; */
}

.login__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex: 0.5;
  background-color: white;
}

.login__left > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.login__left > *,
.login__form > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.login__error {
  color: red;
  font-size: small;
}

.login__email {
  width: 100%;
  display: flex;
  padding: 5px;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.login__email:focus-within {
  border-color: var(--color-2);
}

.login__email.error,
.login__email:focus-within.error {
  border-color: red;
}

.login__email > input {
  margin-left: 5px;
  padding: 0;
  width: 100%;
  border: none;
  outline-width: 0;
}

.login__password {
  width: 100%;
  display: flex;
  padding: 5px;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.login__password:focus-within {
  border-color: var(--color-2);
}

.login__password.error,
.login__password:focus-within.error {
  border-color: red;
}

.login__password > input {
  margin-left: 5px;
  padding: 0;
  width: 100%;
  border: none;
  outline-width: 0;
}

.login__password > .MuiSvgIcon-root {
  transform: rotate(30deg);
}

.login__emailButton {
  width: 200px;
  border-radius: 10px !important;
  background: var(--color-2) !important;
  color: white !important;
  font-weight: 600 !important;
}

.login__googleButton {
  width: 200px;
  background: var(--color-4) !important;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
}

.login__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.5;
  padding: 20px;
  background-color: var(--color-5);
  color: white;
}

.login__right > h1 {
  flex: 1;
}

.login__signUp {
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .login__container {
    flex-direction: column;
    min-width: 300px;
    max-width: 400px;
  }
  .login--signup {
    flex-direction: column-reverse;
  }
}
