.dashboard {
  display: flex;
  justify-content: center;
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .dashboard {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.dashboard__emptyInfo {
  max-width: 450px;
  margin-top: 50px;
}
