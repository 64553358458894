.sidebarOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  cursor: pointer;
}

.sidebarOption:hover {
  opacity: 0.7;
  background-color: var(--color-4);
}

.sidebarOption--selected {
  opacity: 0.9;
  background-color: var(--color-4);
}

.sidebarOption__icon {
  padding: 5px;
  font-size: 30px !important;
}

.sidebarOption__channel {
  padding: 10px 0;
}

.sidebarOption__hash {
  padding: 10px;
}

.sidebarOption > h3 {
  font-weight: 500;
}
