.settings {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}

.settings__addButton {
  background: #4caf50 !important;
  color: white !important;
  width: 400px !important;
}
