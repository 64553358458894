.categoryCard {
  width: 400px;
  margin: 20px;
}

.categoryCard__header {
  display: flex;
  /* justify-items: center; */
  align-items: center;
  background-color: var(--color-2);
  padding: 5px 10px;
  color: white;
}

.categoryCard__headerIcon {
  margin-right: 10px;
}

.categoryCard__headerTitle {
  flex: 1;
}

.categoryCard__headerAddIcon {
  transition: all 0.3s ease-in-out;
}

.categoryCard__headerAddIcon:hover {
  cursor: pointer;
  /* font-size: 1.2em !important; */
  transform: scale(1.2);
}

@media only screen and (max-width: 640px) {
  .categoryCard {
    max-width: 400px;
    width: auto;
  }
}
