.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app__body {
  display: flex;
  flex: 1;
}
