.categoryView {
  display: flex;
  justify-content: center;
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .categoryView {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.categoryView__businessList {
  padding: 20px;
  /* border: 1px solid lightgray; */
}

.categoryView__businessListHeader {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: lightgray;
  padding: 10px;
}

.categoryView__businessListHeader__closeIcon {
  cursor: pointer;
}

.categoryView__businessListItem {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.categoryView__businessListItem:last-child {
  border-bottom: 1px solid lightgray;
}

.categoryView__businessListItem__addIcon {
  transform: scale(0.8);
  margin-right: 5px;
  padding: 3px;
  padding-bottom: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.categoryView__businessListItem__addIcon:hover {
  background-color: lightgreen;
}

.categoryView__businessListItem__deleteIcon {
  transform: scale(0.8);
  margin-right: 5px;
  padding: 3px;
  padding-bottom: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.categoryView__businessListItem__deleteIcon:hover {
  background-color: lightcoral;
}

.categoryView__businessListItem__peopleIcon {
  transform: scale(0.8);
  margin-right: 5px;
  padding: 3px;
  padding-bottom: 0;
}

.categoryView__businessListItem__name {
  padding-bottom: 3px;
}

.categoryView__businessListItem__spacer {
  width: 24px;
}
