.addEditBusinessForm {
  padding: 20px;
  min-width: 440px;
}

.addEditBusinessForm > h1 {
  padding-bottom: 20px;
  font-size: 30px;
}

.addEditBusinessForm__businessName {
  display: flex;
  justify-content: space-between;
}

.addEditBusinessForm__businessNameEntry {
  min-width: 300px !important;
}

.addEditBusinessForm__businessTypeSelect {
  min-width: 240px !important;
}

.addEditBusinessForm__businessUrlEntry {
  min-width: 400px !important;
}

.addEditBusinessForm__rewardChartUrlEntry {
  min-width: 400px !important;
}

.addEditBusinessForm__controls {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.addEditBusinessForm__controlSave {
  background: #4caf50 !important;
  color: white !important;
  margin-right: 10px !important;
}

.addEditBusinessForm__controlSave.Mui-disabled {
  background: lightgray !important;
}

.addEditBusinessForm__controlCancel {
  background: #f44336 !important;
  color: white !important;
}
